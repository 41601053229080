<template>
  <div style="margin-bottom: 80px;">
    <div class="mb-4">
      <!-- <div class="step-title text-center mb-2">{{ menuLabel }}</div> -->
      <v-row class="mx-0 my-0">
        <template v-for="(item, index) in baoArr">
          <v-col v-if="item.price > 0" :key="index" cols="12" sm="6" md="6" class="px-1 py-1 mb-1">
            <div class="item-wrap d-flex px-2 py-2">
              <div class="item-left text-center" style="width: 18%">
                <img class="item-img" :src="getItemImg(item.images[0].url)" alt="item" />
              </div>
              <div class="item-right d-flex flex-column justify-center align-start px-1" style="width: 48%">
                <div class="item-label mb-2">{{ item.name }}</div>
                <div class="item-price font-weight-bold">{{ `${$t('lbl.rm')}${priceFormat(item.price)}` }}</div>
              </div>
              <div class="d-flex align-center" style="width: 36%">
                <PlusMinusField class="plus-minus-field" filled outlined dense v-model="baoForm[item.itemId].count" />
              </div>
            </div>
          </v-col>
        </template>
      </v-row>
    </div>
    <div class="bottom-bar d-flex justify-space-between">
      <div class="d-flex flex-column justify-center">
        <div class="total-text">{{ $t('lbl.total') }}</div>
        <div class="total-price">
          <span class="font-14">{{ $t('lbl.rm') }}</span>
          <span>{{ priceFormat(totalPrice) }}</span>
        </div>
      </div>
      <div class="d-flex align-center">
        <AppBtn class="btn-orange-border" :height="40" :loading="loading" @click="preAddToCart">{{ $t('act.addToCart') }}</AppBtn>
      </div>
    </div>
    <CommonModal v-model="successModal" :modal-size="'modal-xs'" :header="false" :footer="false">
      <template #body>
        <div class="modal-body d-flex align-center">
          <v-icon class="mr-2" color="var(--primary-color)" size="45">mdi-check-circle-outline</v-icon>
          <div class="modal-body__text">{{ $t('cart.success.add') }}</div>
        </div>
      </template>
    </CommonModal>
    <MessageModal
      v-if="errMsgModalShow"
      v-model="errMsgModalShow"
      :modal-size="'modal-xs-2'"
      :header-title="msgModalTitle"
      :msg="msgModalBody"
      @confirm="errMsgModalShow = false"
    >
      <template #header>
        <div></div>
      </template>
    </MessageModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { MENU_GRP } from '@/common/enum/menu';
import CommonModal from '@/components/Modal';
import MessageModal from '@/components/Modal/MessageModal';
export default {
  name: 'ItemList',
  components: {
    CommonModal,
    MessageModal,
  },
  data() {
    return {
      baoLimit: 1,
      baoGrpId: null,
      baoForm: {},
      baoArr: [],
      baoArrCnt: [],
      baoArrDisabled: [],

      beverageLimit: 1,
      beverageGrpId: null,
      beverageForm: {},
      beverageDisabled: false,
      beverageGrpItemsArr: [],

      beverageBasePrice: 0,

      totalPrice: 0,
      setPrice: 0,

      loading: false,

      errMsgModalShow: false,
      msgModalTitle: '',
      msgModalBody: 'You have not selected any items.',

      menuItem: {},
      modalShow: false,
      successModal: false,
      defaultImg: require('@/assets/logo.png'),
    };
  },
  computed: {
    ...mapState({
      menuList: state => state.menu.menuList,
      menuItemList: state => state.menu.menuItemList,
      menuItemOptList: state => state.menu.menuItemOptList,
      menuItemOptGrpList: state => state.menu.menuItemOptGrpList,
      menuItemOptGrpSubList: state => state.menu.menuItemOptGrpSubList,
    }),
    menuId() {
      return this.$route.params.menuId;
    },
    menuLabel() {
      return this.menuList.find(item => item.id == this.menuId).name;
    },
    MENU_GRP() {
      return MENU_GRP;
    },
  },
  watch: {
    baoForm: {
      deep: true,
      handler(value) {
        let totalPrice = 0;
        for (let i = 0; i < Object.keys(value).length; i++) {
          let item = value[Object.keys(value)[i]];
          totalPrice = totalPrice + item.count * item.price;
        }
        this.totalPrice = totalPrice;
      },
    },
  },
  created() {
    const menu = this.menuList.find(item => item.id == this.menuId);
    this.SET_DRAWER_TITLE(menu.name);
    if (!this.isUndefinedNullOrEmpty(this.menuItemList) && !this.isUndefinedNullOrEmpty(this.menuId)) {
      // Process bao data
      const menuItems = this.menuItemList.filter(item => item.menuId == this.menuId);

      for (let item of menuItems) {
        this.$set(this.baoForm, item.itemId, {
          ...item,
          count: 0,
        });
      }
      this.baoArr = menuItems;
    }
  },
  methods: {
    ...mapMutations(['SET_DRAWER_TITLE']),
    ...mapActions(['addToCart']),
    preAddToCart() {
      let promises = [];

      for (let key in this.baoForm) {
        let item = this.baoForm[key];
        if (item.count > 0) {
          promises.push(
            this.addToCart({
              qty: item.count,
              price: item.price,
              label: item.label,
              images: item.images,
              menuItemId: item.itemId,
            })
          );
        }
      }

      if (promises.length > 0) {
        return Promise.all(promises).then(() => {
          this.successModal = true;
          setTimeout(() => {
            this.successModal = false;
            this.navigateReplace('MenuAll');
          }, 1000);
        });
      } else {
        this.errMsgModalShow = true;
      }
    },
    getItemImg(imgUrl) {
      if (this.isUndefinedNullOrEmpty(imgUrl)) {
        return this.defaultImg;
      }
      return imgUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin for-xs {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-sm {
  @media (max-width: 959px) {
    @content;
  }
}
.item-wrap {
  // border: 4px solid #000;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
  height: 100%;
}
.item-img {
  max-width: 100px;
  width: 100%;
  @include for-xs {
    max-width: 65px;
  }
}
.item-label {
  color: var(--primary-black-color);
  font-size: 1rem;
  font-weight: 500;
}
.item-price {
  color: var(--red-common);
  font-size: 1rem;
  font-weight: 500;
}
.step-title {
  color: var(--primary-black-color);
  font-size: 1.4rem;
  font-weight: 500;
}
.plus-minus-field {
  max-width: 200px;
}
.qty-bar {
  max-width: 200px;
}
.bottom-bar {
  background-color: var(--primary-black-color);
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: 12px;
  height: 80px;

  .total-text {
    color: var(--primary-color);
    font-size: 16px;
  }
  .total-price {
    color: var(--primary-color);
    font-size: 22px;
    font-weight: 500;
  }
}
.modal-body {
  background-color: #231f20;
  height: 100px;

  &__text {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 600;
  }
}
</style>
